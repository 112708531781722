import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			count: null
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section-region');
		if (results.length) {
			//console.log('resultsFields::', results);
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var unitId;
		let e3 = document.querySelector("[data-unit-id]");
		if (e3) {
			unitId = parseInt(e3.getAttribute('data-unit-id'));
			//console.log('Unit id int:' + unitId);
		}

		var regionId;
		let e5 = document.querySelector("[data-region-id]");
		//console.log('region:::::::::' + e5.getAttribute('data-region-id'));
		if (e5) {
			//console.log("e5.getAttribute('data-region-id'):" + e5.getAttribute('data-region-id'));
			regionId = parseInt(e5.getAttribute('data-region-id'));
			//console.log('Region id int:' + regionId);
		}
		var street;
		let e6 = document.querySelector("[data-street]");
		if (e6) {
			street = e5.getAttribute('data-street');
			//console.log('Street:' + street);
		}
		var req;
		console.log('------------------------- Filter by region -----------------');
		if (regionId) {
			//console.log('Filter nach Region');
			req = {
				fields: this.resultFields,
				filter: {
					'region.id': regionId
				},
				max: 6,
				sorting: 'random'
			};

		} else if (street) {
			//console.log('Filter nach Strasse');
			req = {
				fields: this.resultFields,
				filter: {
					'address.street': street
				},
				max: 6,
				sorting: 'random'
			};

		}


		doReq('searchUnits', req)
			.then((res) => {
				var foundUnits = [];
				if (res.units) {
					//console.log('ResUnits::', res.units);
					for (let i = 0; i < res.units.length; i++) {
						if (res.units[i]._id !== unitId) {
							foundUnits.push(res.units[i]);
						}

					}
				}
				//console.log('foundUnits::', foundUnits);
				this.count = foundUnits.length;
				this.units = foundUnits;


			});


	},
	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},


		applySlider: function () {
			$(".owl-carousel.similar-regions").owlCarousel({
				loop: false,
				margin: 30,
				smartSpeed: 3000,
				autoplay: false,
				nav: true,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					996: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		}
	}

};