import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			count: null
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section-facility');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var unitGroups;
		let e3 = document.querySelectorAll("[data-unit-group-id]");
		var unitGroupId;
		let parseErrors = [];

		if (e3.length > 0) {
			for (let element of e3) {
				const e3Attribute = element.getAttribute('data-unit-group-id');

				let e3Ids;
				try {
					e3Ids = JSON.parse(e3Attribute);
				} catch (e) {
					parseErrors.push(`Failed to parse data-unit-group-id: ${e3Attribute}`);
					continue;
				}

				if (Array.isArray(e3Ids)) {
					if (e3Ids.includes(50031)) {
						unitGroupId = 50031;
						break;
					} else if (e3Ids.includes(50374)) {
						unitGroupId = 50374;
						break;
					} else if (e3Ids.includes(50375)) {
						unitGroupId = 50375;
						break;
					}
				} else {
					parseErrors.push(`Invalid data-unit-group-id format, expected an array: ${e3Attribute}`);
				}
			}
		} else {
			console.warn("No elements found with [data-unit-group-id] attribute");
		}

		if (parseErrors.length > 0) {
			parseErrors.forEach(error => console.warn(error));
		}

		var unitId;
		let e4 = document.querySelector("[data-unit-id]");
		if (e4) {
			unitId = parseInt(e4.getAttribute('data-unit-id'));
		}
		var req;


		if (unitGroupId) {
			req = {
				fields: this.resultFields,
				filter: {
					'unitgroupid': unitGroupId
				},
				max: 6,
				sorting: 'random'
			};

		}


		doReq('searchUnits', req)
			.then((res) => {
				var foundUnits = [];
				if (res.units) {
					for (let i = 0; i < res.units.length; i++) {
						if (res.units[i]._id !== unitId) {
							foundUnits.push(res.units[i]);
						}

					}
				}
				this.count = foundUnits.length;
				this.units = foundUnits;


			});


	},
	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},


		applySlider: function () {
			$(".owl-carousel.similar-facility").owlCarousel({
				loop: false,
				margin: 30,
				smartSpeed: 3000,
				autoplay: false,
				nav: true,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					996: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		}
	}

};