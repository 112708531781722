const {
	href, origin, pathname, search
} = window.location;

const redirects = new Map([
	["/App.-Lucas_lang,en,pid,57571,nid,132531,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/LUCAS"],
	["/App.-Lucas_lang,en,pid,57571,nid,132531,type,newsdetail.html?casaroomtype=77&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/LUCAS"],
	["/App.-Lucas_pid,55327,nid,132531,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/LUCAS"],
	["/App.-Lucas_pid,55327,nid,132531,type,newsdetail.html?casaroomtype=77&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/LUCAS"],
	["/Appartement-Am-Brummelbach_lang,en,pid,57571,nid,116979,type,newsdetail.html", "/en/Oesterreich/Westendorf/BRUM"],
	["/Appartement-Am-Brummelbach_lang,en,pid,57571,nid,116979,type,newsdetail.html?casaroomtype=69&casaadults=2", "/en/Oesterreich/Westendorf/BRUM"],
	["/Appartement-Am-Brummelbach_pid,55327,nid,116979,type,newsdetail.html", "/de/Oesterreich/Westendorf/BRUM"],
	["/Appartement-Am-Brummelbach_pid,55327,nid,116979,type,newsdetail.html?casaroomtype=69&casaadults=2", "/de/Oesterreich/Westendorf/BRUM"],
	["/Appartement-Consti_lang,en,pid,57571,nid,139227,type,newsdetail.html", "/en/Oesterreich/Hopfgarten/CONST"],
	["/Appartement-Consti_lang,en,pid,57571,nid,139227,type,newsdetail.html?casaroomtype=82&casaadults=2", "/en/Oesterreich/Hopfgarten/CONST"],
	["/Appartement-Consti_pid,55327,nid,139227,type,newsdetail.html", "/de/Oesterreich/Hopfgarten/CONST"],
	["/Appartement-Consti_pid,55327,nid,139227,type,newsdetail.html?casaroomtype=82&casaadults=2", "/de/Oesterreich/Hopfgarten/CONST"],
	["/Appartement-Goasblick_lang,en,pid,57571,nid,105795,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/Goas"],
	["/Appartement-Goasblick_lang,en,pid,57571,nid,105795,type,newsdetail.html?casaroomtype=64&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/Goas"],
	["/Appartement-Goasblick_pid,55327,nid,105795,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/Goas"],
	["/Appartement-Goasblick_pid,55327,nid,105795,type,newsdetail.html?casaroomtype=64&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/Goas"],
	["/Appartement-Hafkamp_lang,en,pid,57571,nid,144415,type,newsdetail.html", "/en/Oesterreich/Reith.bei.Kitzbuehel/HAFK"],
	["/Appartement-Hafkamp_lang,en,pid,57571,nid,144415,type,newsdetail.html?casaroomtype=85&casaadults=2", "/en/Oesterreich/Reith.bei.Kitzbuehel/HAFK"],
	["/Appartement-Hafkamp_pid,55327,nid,144415,type,newsdetail.html", "/de/Oesterreich/Reith.bei.Kitzbuehel/HAFK"],
	["/Appartement-Hafkamp_pid,55327,nid,144415,type,newsdetail.html?casaroomtype=85&casaadults=2", "/de/Oesterreich/Reith.bei.Kitzbuehel/HAFK"],
	["/Appartement-Hahnenkamm_lang,en,pid,57571,nid,132248,type,newsdetail.html", "/en/Oesterreich/Kitzbuehel/HAHN"],
	["/Appartement-Hahnenkamm_lang,en,pid,57571,nid,132248,type,newsdetail.html?casaroomtype=73&casaadults=2", "/en/Oesterreich/Kitzbuehel/HAHN"],
	["/Appartement-Hahnenkamm_pid,55327,nid,132248,type,newsdetail.html", "/de/Oesterreich/Kitzbuehel/HAHN"],
	["/Appartement-Hahnenkamm_pid,55327,nid,132248,type,newsdetail.html?casaroomtype=73&casaadults=2", "/de/Oesterreich/Kitzbuehel/HAHN"],
	["/Appartement-Herliana_lang,en,pid,57571,nid,98716,type,newsdetail.html", "/en/Oesterreich/Reith.bei.Kitzbuehel/HERLI"],
	["/Appartement-Herliana_lang,en,pid,57571,nid,98716,type,newsdetail.html?casaroomtype=19&casaadults=2", "/en/Oesterreich/Reith.bei.Kitzbuehel/HERLI"],
	["/Appartement-Herliana_pid,55327,nid,98716,type,newsdetail.html", "/de/Oesterreich/Reith.bei.Kitzbuehel/HERLI"],
	["/Appartement-Hornblick_lang,en,pid,57571,nid,99073,type,newsdetail.html", "/en/Oesterreich/Reith.bei.Kitzbuehel/HORN"],
	["/Appartement-Hornblick_lang,en,pid,57571,nid,99073,type,newsdetail.html?casaroomtype=63&casaadults=2", "/en/Oesterreich/Reith.bei.Kitzbuehel/HORN"],
	["/Appartement-Hornblick_pid,55327,nid,99073,type,newsdetail.html", "/de/Oesterreich/Reith.bei.Kitzbuehel/HORN"],
	["/Appartement-Hornblick_pid,55327,nid,99073,type,newsdetail.html?casaroomtype=63&casaadults=2", "/de/Oesterreich/Reith.bei.Kitzbuehel/HORN"],
	["/Appartement-Lana_lang,en,pid,57571,nid,133966,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/Lana"],
	["/Appartement-Lana_lang,en,pid,57571,nid,133966,type,newsdetail.html?casaroomtype=79&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/Lana"],
	["/Appartement-Lana_pid,55327,nid,133966,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/Lana"],
	["/Appartement-Lana_pid,55327,nid,133966,type,newsdetail.html?casaroomtype=79&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/Lana"],
	["/Appartement-Lichtblick_lang,en,pid,57571,nid,135487,type,newsdetail.html", "/en/Oesterreich/Westendorf/LICHT"],
	["/Appartement-Lichtblick_lang,en,pid,57571,nid,135487,type,newsdetail.html?casaroomtype=80&casaadults=2", "/en/Oesterreich/Westendorf/LICHT"],
	["/Appartement-Lichtblick_pid,55327,nid,135487,type,newsdetail.html", "/de/Oesterreich/Westendorf/LICHT"],
	["/Appartement-Lichtblick_pid,55327,nid,135487,type,newsdetail.html?casaroomtype=80&casaadults=2", "/de/Oesterreich/Westendorf/LICHT"],
	["/Appartement-Mia_lang,en,pid,57571,nid,131447,type,newsdetail.html", "/en/Oesterreich/Hopfgarten/Mia"],
	["/Appartement-Mia_lang,en,pid,57571,nid,131447,type,newsdetail.html?casaroomtype=71&casaadults=2", "/en/Oesterreich/Hopfgarten/Mia"],
	["/Appartement-Mia_pid,55327,nid,131447,type,newsdetail.html", "/de/Oesterreich/Hopfgarten/Mia"],
	["/Appartement-Mia_pid,55327,nid,131447,type,newsdetail.html?casaroomtype=71&casaadults=2", "/de/Oesterreich/Hopfgarten/Mia"],
	["/Appartement-Milda_lang,en,pid,57571,nid,131513,type,newsdetail.html", "/en/Oesterreich/Hopfgarten/MILDA"],
	["/Appartement-Milda_lang,en,pid,57571,nid,131513,type,newsdetail.html?casaroomtype=72&casaadults=2", "/en/Oesterreich/Hopfgarten/MILDA"],
	["/Appartement-Milda_pid,55327,nid,131513,type,newsdetail.html", "/de/Oesterreich/Hopfgarten/MILDA"],
	["/Appartement-Milda_pid,55327,nid,131513,type,newsdetail.html?casaroomtype=72&casaadults=2", "/de/Oesterreich/Hopfgarten/MILDA"],
	["/Appartement-Pieter_lang,en,pid,57571,nid,98700,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/PIETE"],
	["/Appartement-Pieter_lang,en,pid,57571,nid,98700,type,newsdetail.html?casaroomtype=59&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/PIETE"],
	["/Appartement-Pieter_pid,55327,nid,98700,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/PIETE"],
	["/Appartement-Pieter_pid,55327,nid,98700,type,newsdetail.html?casaroomtype=59&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/PIETE"],
	["/Appartement-Resi_lang,en,pid,57571,nid,146959,type,newsdetail.html", "/en/Oesterreich/Brixen.im.Thale/RESI"],
	["/Appartement-Resi_lang,en,pid,57571,nid,146959,type,newsdetail.html?casaroomtype=90&casaadults=2", "/en/Oesterreich/Brixen.im.Thale/RESI"],
	["/Appartement-Resi_pid,55327,nid,146959,type,newsdetail.html", "/de/Oesterreich/Brixen.im.Thale/RESI"],
	["/Appartement-Resi_pid,55327,nid,146959,type,newsdetail.html?casaroomtype=90&casaadults=2", "/de/Oesterreich/Brixen.im.Thale/RESI"],
	["/Appartement-Robert_lang,en,pid,57571,nid,141823,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/Rober"],
	["/Appartement-Robert_lang,en,pid,57571,nid,141823,type,newsdetail.html?casaroomtype=83&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/Rober"],
	["/Appartement-Robert_pid,55327,nid,141823,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/Rober"],
	["/Appartement-Robert_pid,55327,nid,141823,type,newsdetail.html?casaroomtype=83&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/Rober"],
	["/Appartement-Sepp_lang,en,pid,57571,nid,146958,type,newsdetail.html", "/de/s#?name=sepp"],
	["/Appartement-Sepp_lang,en,pid,57571,nid,146958,type,newsdetail.html?casaroomtype=89&casaadults=2", "/de/s#?name=sepp"],
	["/Appartement-Sepp_pid,55327,nid,146958,type,newsdetail.html", "/de/s#?name=sepp"],
	["/Appartement-Sepp_pid,55327,nid,146958,type,newsdetail.html?casaroomtype=89&casaadults=2", "/de/s#?name=sepp"],
	["/Appartement-Sibylle_lang,en,pid,57571,nid,98695,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/SIBYL"],
	["/Appartement-Sibylle_lang,en,pid,57571,nid,98695,type,newsdetail.html?casaroomtype=33&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/SIBYL"],
	["/Appartement-Sibylle_pid,55327,nid,98695,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/SIBYL"],
	["/Appartement-Sibylle_pid,55327,nid,98695,type,newsdetail.html?casaroomtype=33&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/SIBYL"],
	["/Appartement-Teresa_lang,en,pid,57571,nid,98696,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/TERES"],
	["/Appartement-Teresa_lang,en,pid,57571,nid,98696,type,newsdetail.html?casaroomtype=25&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/TERES"],
	["/Appartement-Teresa_pid,55327,nid,98696,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/TERES"],
	["/Appartement-Teresa_pid,55327,nid,98696,type,newsdetail.html?casaroomtype=25&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/TERES"],
	["/Appartement-Teresa-II_lang,en,pid,57571,nid,145985,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/TERE2"],
	["/Appartement-Teresa-II_lang,en,pid,57571,nid,145985,type,newsdetail.html?casaroomtype=88&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/TERE2"],
	["/Appartement-Teresa-II_pid,55327,nid,145985,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/TERE2"],
	["/Appartement-Teresa-II_pid,55327,nid,145985,type,newsdetail.html?casaroomtype=88&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/TERE2"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-1_lang,en,pid,57571,nid,98717,type,newsdetail.html", "/en/Oesterreich/Westendorf/ADL1"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-1_lang,en,pid,57571,nid,98717,type,newsdetail.html?casaroomtype=38&casaadults=2", "/en/Oesterreich/Westendorf/ADL1"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-1_pid,55327,nid,98717,type,newsdetail.html", "/de/Oesterreich/Westendorf/ADL1"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-1_pid,55327,nid,98717,type,newsdetail.html?casaroomtype=38&casaadults=2", "/de/Oesterreich/Westendorf/ADL1"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-2_lang,en,pid,57571,nid,98718,type,newsdetail.html", "/en/Oesterreich/Westendorf/ADL2"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-2_lang,en,pid,57571,nid,98718,type,newsdetail.html?casaroomtype=39&casaadults=2", "/en/Oesterreich/Westendorf/ADL2"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-2_pid,55327,nid,98718,type,newsdetail.html", "/de/Oesterreich/Westendorf/ADL2"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-2_pid,55327,nid,98718,type,newsdetail.html?casaroomtype=39&casaadults=2", "/de/Oesterreich/Westendorf/ADL2"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-3_lang,en,pid,57571,nid,98719,type,newsdetail.html", "/en/Oesterreich/Westendorf/ADL3"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-3_lang,en,pid,57571,nid,98719,type,newsdetail.html?casaroomtype=40&casaadults=2", "/en/Oesterreich/Westendorf/ADL3"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-3_pid,55327,nid,98719,type,newsdetail.html", "/de/Oesterreich/Westendorf/ADL3"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-3_pid,55327,nid,98719,type,newsdetail.html?casaroomtype=40&casaadults=2", "/de/Oesterreich/Westendorf/ADL3"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-4_lang,en,pid,57571,nid,98720,type,newsdetail.html?casaroomtype=41&casaadults=2", "/en/Oesterreich/Westendorf/ADL4"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-4_pid,55327,nid,98720,type,newsdetail.html", "/de/Oesterreich/Westendorf/ADL4"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-4_pid,55327,nid,98720,type,newsdetail.html?casaroomtype=41&casaadults=2", "/de/Oesterreich/Westendorf/ADL4"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-5_lang,en,pid,57571,nid,98721,type,newsdetail.html", "/en/Oesterreich/Westendorf/ADL5"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-5_lang,en,pid,57571,nid,98721,type,newsdetail.html?casaroomtype=42&casaadults=2", "/en/Oesterreich/Westendorf/ADL5"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-5_pid,55327,nid,98721,type,newsdetail.html", "/de/Oesterreich/Westendorf/ADL5"],
	["/Appartementhaus-Adler-ehemaliges-Haus-Nadine-Appartement-5_pid,55327,nid,98721,type,newsdetail.html?casaroomtype=42&casaadults=2", "/de/Oesterreich/Westendorf/ADL5"],
	["/Das-Chalet_lang,en,pid,57571,nid,132249,type,newsdetail.html", "/en/Oesterreich/Kitzbuehel/CHALE"],
	["/Das-Chalet_lang,en,pid,57571,nid,132249,type,newsdetail.html?casaroomtype=74&casaadults=2", "/en/Oesterreich/Kitzbuehel/CHALE"],
	["/Das-Chalet_pid,55327,nid,132249,type,newsdetail.html", "/de/Oesterreich/Kitzbuehel/CHALE"],
	["/Das-Chalet_pid,55327,nid,132249,type,newsdetail.html?casaroomtype=74&casaadults=2", "/de/Oesterreich/Kitzbuehel/CHALE"],
	["/de/anfrage/", "/de/s"],
	["/de/anfrage/?casaroomtype=19", "/de/s"],
	["/de/anfrage/?casaroomtype=19&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=2", "/de/s"],
	["/de/anfrage/?casaroomtype=2&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=25", "/de/s"],
	["/de/anfrage/?casaroomtype=25&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=33", "/de/s"],
	["/de/anfrage/?casaroomtype=33&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=34", "/de/s"],
	["/de/anfrage/?casaroomtype=34&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=38", "/de/s"],
	["/de/anfrage/?casaroomtype=38&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=39", "/de/s"],
	["/de/anfrage/?casaroomtype=39&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=4", "/de/s"],
	["/de/anfrage/?casaroomtype=4&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=40", "/de/s"],
	["/de/anfrage/?casaroomtype=40&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=41", "/de/s"],
	["/de/anfrage/?casaroomtype=41&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=42", "/de/s"],
	["/de/anfrage/?casaroomtype=42&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=45", "/de/s"],
	["/de/anfrage/?casaroomtype=45&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=52", "/de/s"],
	["/de/anfrage/?casaroomtype=52&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=53", "/de/s"],
	["/de/anfrage/?casaroomtype=53&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=54", "/de/s"],
	["/de/anfrage/?casaroomtype=54&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=58", "/de/s"],
	["/de/anfrage/?casaroomtype=58&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=59", "/de/s"],
	["/de/anfrage/?casaroomtype=59&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=63", "/de/s"],
	["/de/anfrage/?casaroomtype=63&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=64", "/de/s"],
	["/de/anfrage/?casaroomtype=64&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=69", "/de/s"],
	["/de/anfrage/?casaroomtype=69&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=71", "/de/s"],
	["/de/anfrage/?casaroomtype=71&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=72", "/de/s"],
	["/de/anfrage/?casaroomtype=72&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=73", "/de/s"],
	["/de/anfrage/?casaroomtype=73&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=74", "/de/s"],
	["/de/anfrage/?casaroomtype=74&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=77", "/de/s"],
	["/de/anfrage/?casaroomtype=77&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=79&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=80", "/de/s"],
	["/de/anfrage/?casaroomtype=80&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=82", "/de/s"],
	["/de/anfrage/?casaroomtype=82&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=83", "/de/s"],
	["/de/anfrage/?casaroomtype=83&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=85", "/de/s"],
	["/de/anfrage/?casaroomtype=85&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=86", "/de/s"],
	["/de/anfrage/?casaroomtype=86&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=88", "/de/s"],
	["/de/anfrage/?casaroomtype=88&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=89", "/de/s"],
	["/de/anfrage/?casaroomtype=89&casaadults=2", "/de/s"],
	["/de/anfrage/?casaroomtype=90", "/de/s"],
	["/de/anfrage/?casaroomtype=90&casaadults=2", "/de/s"],
	["/de/appartements/mieten/", "/de/s"],
	["/de/appartements/service/", "/de/apartments-vermieten"],
	["/de/appartements/vermieten/", "/de/apartments-vermieten"],
	["/de/buchen/", "/de/s"],
	["/de/buchen/?casaroomtype=19", "/de/s"],
	["/de/buchen/?casaroomtype=2", "/de/s"],
	["/de/buchen/?casaroomtype=25", "/de/s"],
	["/de/buchen/?casaroomtype=33", "/de/s"],
	["/de/buchen/?casaroomtype=34", "/de/s"],
	["/de/buchen/?casaroomtype=38", "/de/s"],
	["/de/buchen/?casaroomtype=39", "/de/s"],
	["/de/buchen/?casaroomtype=4", "/de/s"],
	["/de/buchen/?casaroomtype=40", "/de/s"],
	["/de/buchen/?casaroomtype=41", "/de/s"],
	["/de/buchen/?casaroomtype=42", "/de/s"],
	["/de/buchen/?casaroomtype=45", "/de/s"],
	["/de/buchen/?casaroomtype=52", "/de/s"],
	["/de/buchen/?casaroomtype=53", "/de/s"],
	["/de/buchen/?casaroomtype=54", "/de/s"],
	["/de/buchen/?casaroomtype=58", "/de/s"],
	["/de/buchen/?casaroomtype=59", "/de/s"],
	["/de/buchen/?casaroomtype=63", "/de/s"],
	["/de/buchen/?casaroomtype=64", "/de/s"],
	["/de/buchen/?casaroomtype=69", "/de/s"],
	["/de/buchen/?casaroomtype=71", "/de/s"],
	["/de/buchen/?casaroomtype=72", "/de/s"],
	["/de/buchen/?casaroomtype=73", "/de/s"],
	["/de/buchen/?casaroomtype=74", "/de/s"],
	["/de/buchen/?casaroomtype=77", "/de/s"],
	["/de/buchen/?casaroomtype=79", "/de/s"],
	["/de/buchen/?casaroomtype=82", "/de/s"],
	["/de/buchen/?casaroomtype=83", "/de/s"],
	["/de/buchen/?casaroomtype=85", "/de/s"],
	["/de/buchen/?casaroomtype=86", "/de/s"],
	["/de/buchen/?casaroomtype=88", "/de/s"],
	["/de/buchen/?casaroomtype=89", "/de/s"],
	["/de/buchen/?casaroomtype=90", "/de/s"],
	["/de/datenschutz/", "/de/datenschutz"],
	["/de/impressum/", "/de/impressum"],
	["/de/infos/", "/de"],
	["/de/infos/liftstatus/", "/de/liftstatus"],
	["/de/infos/liftstatus/<div%20class=\"mco-widget-liftstate\"%20data-token=\"UTjDCiWmJP6HkJ9P6F6aX-1GoLsZqP27W8Pg_ccBI1kd0\"%20data-id=\"5\"></div>%20<script%20src=\"https://insider.brixental.com/static/widgets/js/widget-liftstate.js\"></script>", "/de/liftstatus"],
	["/de/infos/partnerbetriebe/", "/de/news"],
	["/de/infos/schneebericht/", "/de/schneebericht"],
	["/de/infos/webcams/", "/de/webcams"],
	["/de/kontakt/", "/de/kontakt"],
	["/de/news/", "/de/news"],
	["/de/region/", "/de"],
	["/de/region/jochberg/", "/de"],
	["/de/region/kirchberg/", "/de/Oesterreich/Kirchberg.in.Tirol"],
	["/de/region/kitzbuehel/", "/de/Oesterreich/Kitzbuehel"],
	["/de/region/reith/", "/de/Oesterreich/Reith.bei.Kitzbuehel"],
	["/de/region/westendorf/", "/de/Oesterreich/Westendorf"],
	["/en/apartments/about_us/", "/en/about-us"],
	["/en/apartments/rent_out/", "/en/apartments-rent"],
	["/en/apartments/rent/", "/en/s"],
	["/en/booking/", "/en/s"],
	["/en/booking/?casaroomtype=19", "/en/s"],
	["/en/booking/?casaroomtype=2", "/en/s"],
	["/en/booking/?casaroomtype=25", "/en/s"],
	["/en/booking/?casaroomtype=33", "/en/s"],
	["/en/booking/?casaroomtype=34", "/en/s"],
	["/en/booking/?casaroomtype=38", "/en/s"],
	["/en/booking/?casaroomtype=39", "/en/s"],
	["/en/booking/?casaroomtype=4", "/en/s"],
	["/en/booking/?casaroomtype=40", "/en/s"],
	["/en/booking/?casaroomtype=41", "/en/s"],
	["/en/booking/?casaroomtype=42", "/en/s"],
	["/en/booking/?casaroomtype=45", "/en/s"],
	["/en/booking/?casaroomtype=52", "/en/s"],
	["/en/booking/?casaroomtype=53", "/en/s"],
	["/en/booking/?casaroomtype=54", "/en/s"],
	["/en/booking/?casaroomtype=58", "/en/s"],
	["/en/booking/?casaroomtype=59", "/en/s"],
	["/en/booking/?casaroomtype=63", "/en/s"],
	["/en/booking/?casaroomtype=64", "/en/s"],
	["/en/booking/?casaroomtype=69", "/en/s"],
	["/en/booking/?casaroomtype=71", "/en/s"],
	["/en/booking/?casaroomtype=72", "/en/s"],
	["/en/booking/?casaroomtype=73", "/en/s"],
	["/en/booking/?casaroomtype=74", "/en/s"],
	["/en/booking/?casaroomtype=77", "/en/s"],
	["/en/booking/?casaroomtype=79", "/en/s"],
	["/en/booking/?casaroomtype=80", "/en/s"],
	["/en/booking/?casaroomtype=82", "/en/s"],
	["/en/booking/?casaroomtype=83", "/en/s"],
	["/en/booking/?casaroomtype=85", "/en/s"],
	["/en/booking/?casaroomtype=86", "/en/s"],
	["/en/booking/?casaroomtype=88", "/en/s"],
	["/en/booking/?casaroomtype=89", "/en/s"],
	["/en/booking/?casaroomtype=90", "/en/s"],
	["/en/contact/", "/en/contact"],
	["/en/information/", "/en/news"],
	["/en/information/lift_status/", "/en/liftstatus"],
	["/en/information/partners/", "/en/news"],
	["/en/information/snow_report/", "/en/snow-report"],
	["/en/information/webcams/", "/en/webcams"],
	["/en/inquity/", "/en/s"],
	["/en/inquity/?casaroomtype=19", "/en/s"],
	["/en/inquity/?casaroomtype=19&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=2", "/en/s"],
	["/en/inquity/?casaroomtype=2&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=25", "/en/s"],
	["/en/inquity/?casaroomtype=25&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=33", "/en/s"],
	["/en/inquity/?casaroomtype=33&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=34", "/en/s"],
	["/en/inquity/?casaroomtype=34&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=38", "/en/s"],
	["/en/inquity/?casaroomtype=38&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=39", "/en/s"],
	["/en/inquity/?casaroomtype=39&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=4", "/en/s"],
	["/en/inquity/?casaroomtype=4&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=40", "/en/s"],
	["/en/inquity/?casaroomtype=40&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=41", "/en/s"],
	["/en/inquity/?casaroomtype=41&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=42", "/en/s"],
	["/en/inquity/?casaroomtype=42&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=45&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=52", "/en/s"],
	["/en/inquity/?casaroomtype=52&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=53", "/en/s"],
	["/en/inquity/?casaroomtype=53&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=54", "/en/s"],
	["/en/inquity/?casaroomtype=54&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=58", "/en/s"],
	["/en/inquity/?casaroomtype=58&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=59", "/en/s"],
	["/en/inquity/?casaroomtype=59&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=63", "/en/s"],
	["/en/inquity/?casaroomtype=63&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=64", "/en/s"],
	["/en/inquity/?casaroomtype=64&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=69", "/en/s"],
	["/en/inquity/?casaroomtype=69&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=71", "/en/s"],
	["/en/inquity/?casaroomtype=71&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=72", "/en/s"],
	["/en/inquity/?casaroomtype=72&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=73", "/en/s"],
	["/en/inquity/?casaroomtype=73&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=74", "/en/s"],
	["/en/inquity/?casaroomtype=77", "/en/s"],
	["/en/inquity/?casaroomtype=77&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=79", "/en/s"],
	["/en/inquity/?casaroomtype=79&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=80", "/en/s"],
	["/en/inquity/?casaroomtype=80&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=82", "/en/s"],
	["/en/inquity/?casaroomtype=82&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=83", "/en/s"],
	["/en/inquity/?casaroomtype=83&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=85", "/en/s"],
	["/en/inquity/?casaroomtype=85&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=86", "/en/s"],
	["/en/inquity/?casaroomtype=86&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=88", "/en/s"],
	["/en/inquity/?casaroomtype=88&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=89", "/en/s"],
	["/en/inquity/?casaroomtype=89&casaadults=2", "/en/s"],
	["/en/inquity/?casaroomtype=90", "/en/s"],
	["/en/inquity/?casaroomtype=90&casaadults=2", "/en/s"],
	["/en/news/", "/en/news"],
	["/en/region/", "/en"],
	["/en/region/jochberg/", "/en"],
	["/en/region/kirchberg/", "/en/Oesterreich/Kirchberg.in.Tirol"],
	["/en/region/kitzbuehel/", "/en/Oesterreich/Kitzbuehel"],
	["/en/region/reith/", "/en/Oesterreich/Reith.bei.Kitzbuehel"],
	["/en/region/westendorf/", "/en/Oesterreich/Westendorf"],
	["/Ferienhaus-Lindner_lang,en,pid,57571,nid,98706,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/LINDN"],
	["/Ferienhaus-Lindner_lang,en,pid,57571,nid,98706,type,newsdetail.html?casaroomtype=34&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/LINDN"],
	["/Ferienhaus-Lindner_pid,55327,nid,98706,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/LINDN"],
	["/Ferienhaus-Lindner_pid,55327,nid,98706,type,newsdetail.html?casaroomtype=34&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/LINDN"],
	["/Haus-Gabriele-Appartement-Barbara_lang,en,pid,57571,nid,98645,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/Barba"],
	["/Haus-Gabriele-Appartement-Barbara_lang,en,pid,57571,nid,98645,type,newsdetail.html?casaroomtype=54&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/Barba"],
	["/Haus-Gabriele-Appartement-Barbara_pid,55327,nid,98645,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/Barba"],
	["/Haus-Gabriele-Appartement-Barbara_pid,55327,nid,98645,type,newsdetail.html?casaroomtype=54&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/Barba"],
	["/Haus-Gabriele-Appartement-Frederic-Tiefparterre_lang,en,pid,57571,nid,98646,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/Frede"],
	["/Haus-Gabriele-Appartement-Frederic-Tiefparterre_lang,en,pid,57571,nid,98646,type,newsdetail.html?casaroomtype=52&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/Frede"],
	["/Haus-Gabriele-Appartement-Frederic-Tiefparterre_pid,55327,nid,98646,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/Frede"],
	["/Haus-Gabriele-Appartement-Frederic-Tiefparterre_pid,55327,nid,98646,type,newsdetail.html?casaroomtype=52&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/Frede"],
	["/Haus-Gabriele-Appartement-Hannes_lang,en,pid,57571,nid,143082,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/HANN"],
	["/Haus-Gabriele-Appartement-Hannes_lang,en,pid,57571,nid,143082,type,newsdetail.html?casaroomtype=79&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/HANN"],
	["/Haus-Gabriele-Appartement-Hannes_pid,55327,nid,143082,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/HANN"],
	["/Haus-Gabriele-Appartement-Hannes_pid,55327,nid,143082,type,newsdetail.html?casaroomtype=79&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/HANN"],
	["/Haus-Gabriele-Appartement-Rene_lang,en,pid,57571,nid,98660,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/Rene"],
	["/Haus-Gabriele-Appartement-Rene_lang,en,pid,57571,nid,98660,type,newsdetail.html?casaroomtype=53&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/Rene"],
	["/Haus-Gabriele-Appartement-Rene_pid,55327,nid,98660,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/Rene"],
	["/Haus-Gabriele-Appartement-Rene_pid,55327,nid,98660,type,newsdetail.html?casaroomtype=53&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/Rene"],
	["/Haus-Milli_lang,en,pid,57571,nid,145598,type,newsdetail.html", "/en/Oesterreich/Kitzbuehel/Milli"],
	["/Haus-Milli_lang,en,pid,57571,nid,145598,type,newsdetail.html?casaroomtype=86&casaadults=2", "/en/Oesterreich/Kitzbuehel/Milli"],
	["/Haus-Milli_pid,55327,nid,145598,type,newsdetail.html", "/de/Oesterreich/Kitzbuehel/Milli"],
	["/Haus-Milli_pid,55327,nid,145598,type,newsdetail.html?casaroomtype=86&casaadults=2", "/de/Oesterreich/Kitzbuehel/Milli"],
	["/Haus-Romantica_lang,en,pid,57571,nid,98693,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/ROMAN"],
	["/Haus-Romantica_lang,en,pid,57571,nid,98693,type,newsdetail.html?casaroomtype=4&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/ROMAN"],
	["/Haus-Romantica_pid,55327,nid,98693,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/ROMAN"],
	["/Haus-Romantica_pid,55327,nid,98693,type,newsdetail.html?casaroomtype=4&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/ROMAN"],
	["/Haus-Sonneck-Appartement-Herbert_lang,en,pid,57571,nid,98671,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/HERBE"],
	["/Haus-Sonneck-Appartement-Herbert_lang,en,pid,57571,nid,98671,type,newsdetail.html?casaroomtype=58&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/HERBE"],
	["/Haus-Sonneck-Appartement-Herbert_pid,55327,nid,98671,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/HERBE"],
	["/Haus-Sonneck-Appartement-Herbert_pid,55327,nid,98671,type,newsdetail.html?casaroomtype=58&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/HERBE"],
	["/Haus-Sonneck-Appartement-Loisi_lang,en,pid,57571,nid,98668,type,newsdetail.html", "/en/Oesterreich/Kirchberg.in.Tirol/LOISI"],
	["/Haus-Sonneck-Appartement-Loisi_lang,en,pid,57571,nid,98668,type,newsdetail.html?casaroomtype=45&casaadults=2", "/en/Oesterreich/Kirchberg.in.Tirol/LOISI"],
	["/Haus-Sonneck-Appartement-Loisi_pid,55327,nid,98668,type,newsdetail.html", "/de/Oesterreich/Kirchberg.in.Tirol/LOISI"],
	["/Haus-Sonneck-Appartement-Loisi_pid,55327,nid,98668,type,newsdetail.html?casaroomtype=45&casaadults=2", "/de/Oesterreich/Kirchberg.in.Tirol/LOISI"],
	["/Haus-Voetter-Lodge-Tirol-2_lang,en,pid,57571,nid,98704,type,newsdetail.html", "/en/s"],
	["/Haus-Voetter-Lodge-Tirol-2_lang,en,pid,57571,nid,98704,type,newsdetail.html?casaroomtype=2&casaadults=2", "/en/s"],
	["/Haus-Voetter-Lodge-Tirol-2_pid,55327,nid,98704,type,newsdetail.html", "/de/s"],
	["/Haus-Voetter-Lodge-Tirol-2_pid,55327,nid,98704,type,newsdetail.html?casaroomtype=2&casaadults=2", "/de/s"],
	["/NV-Appartements-Apartments_lang,en,pid,57571,type,news.html", "/en/s"],
	["/NV-Appartements-Apartments_lang,en,pid,57571,type,news.html?filter_reset=1", "/en/s"],
	["/NV-Appartements-App.-Das-Chalet_pid,70759,conly,1,type,map.html", "/de/Oesterreich/Kitzbuehel/CHALE"],
	["/NV-Appartements-App.-Herliana_pid,55380,conly,1,type,map.html", "/de/Oesterreich/Reith.bei.Kitzbuehel/HERLI"],
	["/NV-Appartements-App.-Hopfgarten_pid,70671,conly,1,type,map.html", "/de/Oesterreich/Hopfgarten"],
	["/NV-Appartements-App.-Hornblick_pid,55670,conly,1,type,map.html", "/de/Oesterreich/Reith.bei.Kitzbuehel/HORN"],
	["/NV-Appartements-App.-Pieter_pid,55369,conly,1,type,map.html", "/de/Oesterreich/Kirchberg.in.Tirol/PIETE"],
	["/NV-Appartements-App.-Sibylle_pid,55367,conly,1,type,map.html", "/de/Oesterreich/Kirchberg.in.Tirol/SIBYL"],
	["/NV-Appartements-App.-Teresa_pid,55368,conly,1,type,map.html", "/de/s#?name=teres"],
	["/NV-Appartements-Appartement-Goasblick_pid,61939,conly,1,type,map.html", "/de/Oesterreich/Kirchberg.in.Tirol/Goas"],
	["/NV-Appartements-Appartement-Lana_pid,71524,conly,1,type,map.html", "/de/Oesterreich/Kirchberg.in.Tirol/Lana"],
	["/NV-Appartements-Appartement-Lucas_pid,70959,conly,1,type,map.html", "/de/Oesterreich/Kirchberg.in.Tirol/LUCAS"],
	["/NV-Appartements-Appartement-Resi_pid,80751,conly,1,type,map.html", "/de/Oesterreich/Brixen.im.Thale/RESI"],
	["/NV-Appartements-Appartement-Sepp_pid,80745,conly,1,type,map.html", "/de/s#?name=sepp"],
	["/NV-Appartements-Appartementhaus-Nadine_pid,55381,conly,1,type,map.html", "/de/s"],
	["/NV-Appartements-Appartements_pid,55327,type,news.html", "/de/s"],
	["/NV-Appartements-Appartements_pid,55327,type,news.html?filter_reset=1", "/de/s"],
	["/NV-Appartements-Aurach_pid,70907,type,firmeninfo.html", "/de"],
	["/NV-Appartements-Brixen-im-Thale_pid,80740,type,firmeninfo.html", "/en/Oesterreich/Brixen.im.Thale"],
	["/NV-Appartements-Brummelbach_pid,62678,conly,1,type,map.html", "/de/Oesterreich/Westendorf/BRUM"],
	["/NV-Appartements-Ferienhaus-Lindner_pid,55371,conly,1,type,map.html", "/de/Oesterreich/Kirchberg.in.Tirol/LINDN"],
	["/NV-Appartements-Haus-Gabriele_pid,55341,conly,1,type,map.html", "/de/s"],
	["/NV-Appartements-Haus-Romantica_pid,55366,conly,1,type,map.html", "/de/Oesterreich/Kirchberg.in.Tirol/ROMAN"],
	["/NV-Appartements-Haus-Sonneck_pid,55359,conly,1,type,map.html", "/de/s"],
	["/NV-Appartements-Haus-Voetter_pid,55370,conly,1,type,map.html", "/de/s"],
	["/NV-Appartements-Hopfgarten_pid,70285,type,firmeninfo.html", "/de/Oesterreich/Hopfgarten"],
	["/NV-Appartements-Radfahren_pid,76618,type,firmeninfo.html", "/de/news"],
	["/NV-Appartements-Routen-und-Touren_pid,76616,type,aktuelles.html", "/de/routen-touren"],
	["/NV-Appartements-routes-and-tours_lang,en,pid,76617,type,news.html", "/en/routes-tours"]
]);


// console.log("pathname", pathname);
// console.log("redirects", redirects);
// console.log("redirects.has(pathname)", redirects.has(pathname));

// Check if the current URL (pathname + search) matches any key in the redirects map
const currentPath = pathname + search;

if (redirects.has(currentPath)) {
	// Redirect to the new path without the search parameters
	location.href = origin + redirects.get(currentPath);
}